import { render, staticRenderFns } from "./ConventionScheduleOverview.vue?vue&type=template&id=24377d24&scoped=true&"
import script from "./ConventionScheduleOverview.vue?vue&type=script&lang=js&"
export * from "./ConventionScheduleOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24377d24",
  null
  
)

export default component.exports